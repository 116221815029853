.App {
  text-align: center;
}

.App-header {
  background-color: #242424;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.chatbot-heading {
  font-size: 3.2em;
  line-height: 1.1;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #242424;
}

main {
  max-width: 800px;
}

p span:first-child {
  margin-right: 0;
}

.user_msg {
  text-align: right;
  margin-left: 30%;
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  visibility: hidden;
  display: none;
}

form {
  text-align: center;
  position: sticky;
  bottom: 0;
}

input:focus {
  outline: none;
}

.text-left {
  text-align: left;
}
